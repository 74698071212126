@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.tips {
  a {
    text-decoration: none;
  }
  .fieldset  {
    margin-bottom: 44px;
  }
}
