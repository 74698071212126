@import '../../assets/styles/colors';

.notification {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 50px 15px;
  z-index : 999;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;

    img {
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.76);
    z-index : 5;
  }

  &__wrapper {
    position: relative;
    display: grid;
    grid-template-areas: 'icon' 'content';
    grid-template-columns: 45px 1fr;
    width : 100%;
    padding: 14px;
    border-radius: 4px;
    background-color: $white;
    border-style: solid;
    border-width: 2px;
    z-index : 10;

    > img {
      display: block;
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }
  }

  &__text {
    h4 {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      color: $black_2;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.25;
      color: $black_2;
    }
  }

  &__ok {border-color: $success;}
  &__info {border-color: $info;}
  &__warning {border-color: $warning;}
  &__bad {border-color: $bad;}
}