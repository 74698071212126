@import '../../assets/styles/mixins';

.view.view__hospitals-list {
  @include viewBaseStyles();

  .banner {
    grid-area: banner;
  }

  .container {
    display: grid;
    grid-template-areas: 'title' 'content';
    grid-template-rows: 71px min-content;
    grid-area: container;

    h4 {
      grid-area: title;
      padding-bottom: 20px;
    }

    .content {
      grid-area: content;
      align-self: start;
    }

    ul {
      padding-left: 20px;

      &.hospitals-list {
        list-style: none;
        padding-left: 0;
        margin-top: 5px;

        ul {
          list-style: initial;
        }
      }

      .hospital {
        &:not(:first-child) {
          margin-top: 10px;
        }

        > strong {
          display: block;
          margin-bottom: 5px;
        }

        .phone {
          margin-top: 5px;
          margin-bottom: 3px;
        }
      }
    }

    .hospital {
    }
  }
}
