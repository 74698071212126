@import '../../assets/styles/colors';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  display: grid;
  grid-template-areas: ". wrapper .";
  grid-template-columns: auto 290px auto;
  box-sizing: border-box;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 415px;
    grid-area: wrapper;
    align-self: center;
    background-color: $white;
    box-shadow: 0 19px 38px #00000042;
    border-radius: 2px;
    z-index: 2;
  }

  &__header {
    display: flex;
    justify-content: flex-end;

    button {
      position: relative;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 0;
      -webkit-appearance: none;
      border: none;
      background: none;

      &:focus {
        outline: none;
      }

      img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  &__content {
    height: calc(100% - 24px);
    padding: 14px;
    box-sizing: border-box;

    &__inner {
      padding-right: 24px;
      overflow-x: hidden;

      p, li {
        font-size: 12px;
        line-height: 16px;
        color: $black;
      }
    }
  }

  &.dialog {
    .modal{
      &__header {
        display: none;
      }

      &__wrapper {
        height: auto;
      }

      &__content {
        &__inner {
          padding-right: 0;
        }
      }
    }
  }
}

.scrollbar-container {
  .ps {
    &__rail-y {
      right: 2px !important;
      opacity: 1 !important;
      background-color: $gray_4 !important;
      width: 5px;
    }

    &__thumb-y {
      right: 0;
      width: 5px !important;
      border-radius: 0;
      background-color: $primary_2 !important;
    }
  }
}
