@import '../../assets/styles/colors';

.view__data {
  padding-bottom: 52px;

  .banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    z-index: 2;
  }

  .container {
    &__content {
      padding-top: 20px;
      display: grid;
      grid-template-areas:
              "title date"
              "form form"
              "title_metrics title_metrics"
              "metrics metrics";

      .title {
        grid-area: title;
        margin-bottom: 32px;
      }

      .date {
        grid-area: date;
        justify-self: end;
      }

      .metrics {
        grid-area: title_metrics;
        margin-top: 40px;
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: bold;
        color: #1b1b1b;
      }

      .form {
        grid-area: form;

        h4 {
          margin-bottom: 17px;
          font-size: 20px;
          font-weight: bold;
          color: $black_2;
        }

        .fieldset {
          + h4 {
            margin-top: 38px
          }
        }
      }
    }
  }

  .footer {
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 2;

    .fieldset {
      padding: 37px 0 24px;
      border-top: 1px solid $gray_4;
    }
  }
}
