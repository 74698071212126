@import '../../assets/styles/colors';

.imprint {
  p {
    display: flex;
    align-items: center;
    margin-top: 10px;

    span {
      width: 60%;
      max-width: 180px;
    }
  }

  ul {
    padding-left: 20px;
    margin: 7px 0;

    &:empty {
      &:after {
        display: list-item;
        font-size: 16px;
        line-height: 21px;
        content: attr(data-empty);
        color: $black;
      }
    }
  }
}

.metrics-paragraph {
  margin-top: 10px;

  p {
    font-size: 12px;
    
    &:first-child {
      font-size: 14px;
      line-height: 1.2;
      margin: 6px 0 3px;
    }
    &:last-child {
      margin-bottom: 10px;
    }
  }
}
