$primary_1: #238366;
$primary_2: #0052a5;
$warning: #d5233f;
$success: #598527;
$info: #eba828;
$bad: #0075e2;
$white: #FFFFFF;
$black: #605E5E;
$black_2: #1b1b1b;
$blue: #B6CFE4;
$blue_2: #0052a5;
$gray_form: #b7b7b7;
$gray_range: #d8d8d8;
$gray_line: #e4e4e4;
$gray_light: #f6f6f6;
$gray_1: #757575;
$gray_2: #707070;
$gray_3: #DBE7F2;
$gray_4: #DCDCDC;
$gray_5: #00000029;
$gray_6: #b7b7b7;
$gray_7: #1b1b1b;
$green: #52DB84;
$orange: #FF7700;
$red: #F23131;
$yellow: #F2C531;

$button_primary: #0052a5;
$button_hover: #0075e2;
$button_disabled: #656565;
$button_disabled_bg: #F1F1F1;
