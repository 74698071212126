.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 338px;
  margin: 0 auto;

  background-size: auto;
  background-repeat: no-repeat;
  background-position: center top;

  @media(max-width: 320px) {
    max-width: 290px;
  }
}
