.view__diagnosis {
  .banner {
    grid-area: banner;
  }

  .container {

    h3 {
      margin-top: 0;
    }

    .content {
      grid-area: content;
      align-self: start;
      padding-top: 30px;
    }

    .fieldset {
      margin-bottom: 30px;
    }

    .button {
      margin: 0 0 33px auto;
    }
  }
}
