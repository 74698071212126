@import '../../assets/styles/colors';

@mixin normalPlaceholder() {
  color: $gray_form;
};

.textarea {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__field {
    height: 132px;
    resize: none;
    border: 1px solid $gray_form;
    font-size: 16px;
    line-height: 1.25;
    padding: 10px;
    color: $black_2;
    letter-spacing: 0;
    -webkit-appearance: none;
    border-radius: 4px;

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      @include normalPlaceholder;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      @include normalPlaceholder;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      @include normalPlaceholder;
    }
    &:-moz-placeholder { /* Firefox 18- */
      @include normalPlaceholder;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0;
    margin-bottom: 5px;
  }
}
