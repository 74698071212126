.user-data {
    
    &__modal-content {
      h4 {
        padding: 20px 0 0;
      }
      
      p {
        padding: 30px 15px;
      }

      button:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__modal-confirmation {
      h4 {
        padding: 20px 0 30px;
      }
    }
}
