@import '../../assets/styles/colors';

@mixin smallPlaceholder() {
  color: $white;
  font-size: 10px;
  line-height: 13px;
};

@mixin normalPlaceholder() {
  color: $gray_form;
};

.input {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__field {
    width: 100%;
    height: 44px;
    padding: 2px 11px;
    border-style: solid;
    border-width: 1px;
    border-color: $gray_form;
    font-size: 18px;
    line-height: 24px;
    color: $black_2;
    letter-spacing: 0;
    -webkit-appearance: none;
    border-radius: 4px;

    &.padding {
      padding-right: 40px;
    }

    &:focus {
      outline: none;
    }

    &--normal {
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @include normalPlaceholder;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        @include normalPlaceholder;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        @include normalPlaceholder;
      }
      &:-moz-placeholder { /* Firefox 18- */
        @include normalPlaceholder;
      }
    }

    &--small {
      width: 80%;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @include smallPlaceholder;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        @include smallPlaceholder;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        @include smallPlaceholder;
      }
      &:-moz-placeholder { /* Firefox 18- */
        @include smallPlaceholder;
      }
    }
  }

  &__phone_number {
    padding-left: 100px!important;
    max-width: 100%;
  }

  &__label {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0;
    margin-bottom: 5px;
  }

  &__description {
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0;
    color: $black;
  }

  &__error {
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0;
    color: $red;
  }

  &__icon, &__reset {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-flow: wrap row;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    img {
      display: block;
      width: 24px;
      height: 24px;
    }
  }
}

.intl-tel-input {
  max-width: 100%;
}
