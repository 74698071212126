@import '../../assets/styles/colors';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 338px;
  height: auto;
  min-height: 44px;
  padding: 7px 12px;
  line-height: 1.5;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0;
  -webkit-appearance: none;
  border-style: none;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Open Sans';

  &:hover,
  &:focus {
    outline: none;
  }

  &--primary {
    background-color: $button_primary;
    color: $white;

    &:hover {
      background-color: $button_hover;
    }

    @media (max-width: 767px) {
      &:hover {
        background-color: $button_primary;
      }
    }

    &:disabled {
      background: $button_disabled;
      color: white;
    }
  }

  &--outline,
  &--secondary {
    background-color: $white;
    color: $button_primary;
    border: 2px solid $button_primary;

    &:hover {
      color: $button_hover;
      border-color: $button_hover;
    }

    @media (max-width: 767px) {
      &:hover {
        color: $button_primary;
        border-color: $button_primary;
      }
    }

    &:disabled {
      background: $button_disabled_bg;
      color: $button_disabled;
      border: 2px solid $button_disabled;
    }
  }

  &--border {
    height: auto;
    min-height: 44px;
    padding: 7px;
    border-radius: 8px;
    border: solid 2px #0452a4;
    background: transparent;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    color: #0452a4;

    &:hover,
    &:focus {
      background-color: transparent;
      outline: none;
    }
  }

  &--ghost,
  &--tertiary {
    text-transform: none;
    
    border: none;
    background-color: transparent;
    color: $black;
    font-size: 13px;
    font-weight: unset;

    &:hover {
      color: $button_hover;
    }

    @media (max-width: 767px) {
      &:hover {
        color: $black;
      }
    }

    &:disabled {
      color: $button_disabled;
    }
  }

  &--white {
    background-color: $white;
    color: $button_primary;
  }

  &--blank {
    background: none;
    color: #dcdcdc;
    box-shadow: none;
  }

  &--black {
    background-color: $black;
    color: $white;
  }

  &--success {
    background-color: $primary_1;
    color: $white;
  }

  &--success-blank {
    background-color: $white;
    border: 1px solid $primary_1;
    color: $black;
  }

  &--gray {
    background-color: $gray_4;
    color: $black;
  }

  &--small {
    min-height: 29px;
  }

  &--size {
    &--small {
      min-width: 74px;
      max-width: min-content;
      padding: 5px 10px;
      text-align: left;
    }
  }

  &__icon {
    width: 16px;
    height: 15px;
    margin-left: 12px;
  }

  &--icon-left {
    flex-direction: row-reverse;

    .button__icon {
      margin-left: 0;
      margin-right: 12px;
    }
  }
}
