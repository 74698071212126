@import '../../assets/styles/colors';

.menu {
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    right: -100%;
    display: flex;
    flex-flow: wrap column;
    width: 100%;
    min-height: calc(100vh - 50px);
    background-color: $white;
    box-shadow: -3px -3px 6px #00000029;
    transition: all 0.2s linear;
    z-index: 99;

    @media screen and (min-width: 556px) {
      width: 320px;
    }

    &.visible {
      right: 0;
      animation-name: visible;
      animation-duration: 0.2s;
    }

    &.hidden {
      right: -100%;
      animation-name: hidden;
      animation-duration: 0.2s;
    }

    @keyframes visible {
      0%   {right: -100%;}
      100% {right: 0;}
    }

    @keyframes hidden {
      0%   {right: 0;}
      100% {right: -100%;}
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 50px;
    padding: 0 24px;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    background-color: $blue_2;

    img {
      display: block;
      width: auto;
      height: 30px;
      margin: 10px 0;
    }

    p {
      color: $blue_2;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0;
      margin: 0;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    &--empty {
      height: 50px;

      +.menu__item:not(.menu__item--empty) {
        border-top: 0;
      }
    }

    &:first-child {
      border-top-width: 0;
    }

    &__icon {
      width: 26px;
      margin-right: 18px;
    }

    &__link {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 24px;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: #0052a5;

      &.disable {
        pointer-events: none;
      }

      &.active {
        color: $button_primary;
        background-color: $gray_3;
      }
    }

    &.disable {
      opacity: 0.5;
    }

    &.bottom {
      border-top-width: 0;
      margin-top: auto;
      margin-bottom: 0;

      ~ .menu__item {
        margin-bottom: 0;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e4e4e4;
    }
  }

  &__logotype {
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
    padding: 24px;

    img {
      display: block;
      max-height: 30px;
    }
  }
}
